@import 'src/assets/styles/constants';

.sidebar-header-menu-button {
  display: flex;
  flex-direction: column;
  color: $cNavCategory;
  font-size: 12px;
  cursor: pointer;

  &__icons {
    display: flex;
    align-items: center;
    margin: 0 20px 0 0;
    color: #8a8692;
    cursor: pointer;

    &-app {
      font-size: 1.6rem;
    }

    &-down {
      margin-left: 4px;
      font-size: 0.7em;
    }
  }
}
