@import 'src/assets/styles/mixins';

.report-tab {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px 0 27px;
  width: 100%;
  height: 40px;
  border: none;
  background: none;
  color: $cNavLink;
  text-decoration: none;
  cursor: pointer;
  @include font(13px, 600, 40px, 'Open sans');

  &__input {
    margin-right: 16px;
    width: 110px;
    font-size: 13px;
  }

  &__title {
    overflow: hidden;
    margin-right: 16px;
    width: 110px;
    color: $cNavLink;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
  }

  &__drag-icon {
    margin-right: 16px;
    min-width: 19px;

    & > span > svg {
      cursor: ns-resize;
    }
  }

  &__settings-icon {
    min-width: 19px;

    & > span > svg {
      cursor: pointer;
    }
  }

  &_active,
  &:hover {
    background: $cBackgroundNavActive;

    & > a {
      color: $cNavLinkActive;
    }

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 2px;
      background: $cBorderLeftNav;
      content: '';
    }
  }
}
