.ai-groupByForm {
  display: flex;
  flex-direction: column;
  padding: 16px;
  max-width: 617px;
  gap: 16px;

  &-button {
    width: fit-content;
  }
}
