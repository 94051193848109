.ai-share {
  &__menu {
    padding: 0 !important;
    width: 600px;
  }

  &__activate-button {
    padding: 0 !important;
    width: 85px;
    height: 24px;
  }

  &__block-title {
    padding: 0 12px;
    font-weight: 600;
    font-size: 16px;
  }

  &__users {
    padding: 12px;

    &-title {
      color: rgb(0 0 0 / 88%);
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    &-select {
      display: grid;
      align-items: center;
      margin-top: 20px;
      min-height: 40px;
      width: 100%;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      grid-template-columns: 1fr min-content;

      & div:first-child .ant-select-arrow {
        display: none;
      }

      & .ant-select-selection-overflow {
        overflow-y: auto;
        min-height: 32px;
        max-height: 124px;
      }
    }
  }

  &__permission {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 12px 0;

    &-title {
      padding-left: 16px;
      font-size: 14px;
      line-height: 22px;
    }

    &-skeleton {
      margin-top: 16px;
      padding: 0 12px;
    }

    &-wrapper {
      overflow-y: auto;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    &-copy {
      color: #531dab;
      cursor: pointer;
    }
  }

  &__access {
    &-count {
      padding-left: 8px;
      color: #9254de;
    }

    &-name {
      font-size: 14px;
      line-height: 22px;
    }

    &-email {
      font-size: 12px;
    }

    &-permission {
      padding: 0 11px;
    }

    &-list {
      padding: 0 12px;
    }

    &-list-wrapper {
      overflow-y: auto;
      max-height: 183px;
    }

    &-skeleton {
      margin-top: 16px;
      padding: 0 12px 12px;
    }
  }

  &__users-select .ant-select-selection-overflow,
  &__permission-wrapper,
  &__access-list-wrapper {
    scrollbar-gutter: stable;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: rgb(138 134 146 / 40%);
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background: rgb(138 134 146 / 20%);
    }
  }
}
