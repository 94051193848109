.ai-custom-formula-mapping {
  & .ant-select.ant-select-disabled .ant-select-selector {
    background: transparent;
  }

  &__cell {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: 7px 6px;
    min-height: 64px;
    width: 72px;
    border: 1px solid #f0f0f0;
    background: rgba(0 0 0 / 2%);
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  &__block {
    display: grid;
    min-width: calc(100% + 72px);
    grid-template-columns: 1fr 72px;
  }

  &__input {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    overflow: auto;
    min-width: 56px;
    min-height: 64px;
    border: 1px solid rgb(0 0 0 / 6%);
    background: #fff;

    &_error {
      outline: 1px solid #ff4d4f;
      outline-offset: -3px;
    }
  }

  &__tags {
    margin-bottom: 3px;
    white-space: normal;
    word-break: break-all;

    & .ant-tag-close-icon svg {
      display: none;
    }
  }

  &__group {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: 7px 6px;
    width: 72px;
    border-left: 2px solid rgb(0 0 0 / 15%);
    background: rgb(0 0 0 / 2%);
    gap: 12px;
  }

  &__datePickerExtraFooter {
    padding: 8px 6px;
    width: 100%;

    > div {
      flex: 1;

      > div {
        width: 100%;
      }
    }
  }

  &__deleteBtn {
    position: relative;
    display: flex;
    align-items: center;
    opacity: 0;
    transition: all 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }
}
