.ai-splitView {
  display: block;
  overflow: hidden;
  margin-left: auto;
  max-width: 100%;
  width: 100%;
  border-left: 1px solid rgb(0 0 0 / 15%);

  &__container {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 112px);
    background: #fff;
  }

  &__separator {
    min-width: 8px;
    max-width: 8px;
    border-right: 1px solid #e4e4e7;
    border-left: 1px solid #e4e4e7;
    background-color: #fafafa;
    cursor: ew-resize;
  }

  &__left-panel {
    min-width: 200px;
  }

  &__right-panel {
    flex-grow: 1;

    &-container {
      display: grid;
      flex-direction: column;
      width: 100%;
      height: calc(100vh - 112px);
      grid-template-rows: 1fr auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-collapse-btn {
      height: fit-content;
      color: rgb(0 0 0 / 45%);
      font-size: 14px;
      line-height: 22px;
      cursor: pointer;
    }

    &-content {
      position: relative;
      overflow-y: auto;
      padding-bottom: 100px;
      min-height: calc(100% - 32px);
      border-radius: 6px 6px 0 0;

      &_withPadding {
        padding-right: 550px;
      }

      &-title {
        display: flex;
        padding: 16px 16px 12px;
        color: rgb(0 0 0 / 88%);
        font-weight: 700;
        font-size: 30px;
        line-height: 38px;
        gap: 8px;

        &-text {
          padding-top: 20px;
        }
      }

      &-preview {
        position: sticky;
        right: 0;
        bottom: 0;
        left: 0;
        border-top: 1px solid rgb(0 0 0 / 15%);
      }

      &-error {
        &-text {
          display: flex;
          align-items: center;
          margin: 0 16px;
          padding: 8px;
          border: 1px solid #fad2cb;
          border-radius: 5px;
          background: #fff2f0;
          font-size: 16px;
          line-height: 18px;
          gap: 8px;
        }

        &-icon {
          color: #ff4d4f;
        }
      }
    }

    &-footer {
      overflow-y: auto;
      min-height: 49px;
      max-width: 100%;
      max-height: calc(60vh - 90px);
      border-top: 1px solid rgb(0 0 0 / 15%);
      background: #fff;
    }
  }
}
