.dataSourceCreationStatus {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
