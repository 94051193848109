.error-boundary {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #fff;

  &__content {
    width: 100%;
  }
}
