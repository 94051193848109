.ai-addStepForm {
  padding: 0 16px;
  max-width: 1400px;

  &__description {
    margin-bottom: 16px;
    color: #71717a;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  &__cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }

  &__card {
    padding: 20px;
    border: 1px solid #f0f0f0;
    border-radius: 12px;
    cursor: pointer;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 24px;
        height: 24px;
      }
    }

    &-title {
      margin-top: 20px;
      color: #1f1f1f;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }

    &-description {
      margin-top: 12px;
      color: #8c8c8c;
      font-size: 14px;
      line-height: 22px;
    }

    & svg path {
      transition: 0.3s all ease;
    }

    &:hover {
      border-color: #b37feb;
      transition: 0.3s all ease;

      svg {
        g {
          opacity: 1;
        }

        path {
          fill: #531dab;
          fill-opacity: 1;
        }
      }
    }
  }
}
