.ai-wizard {
  display: block;
  width: 100%;
  height: 100vh;

  .ant-collapse-content {
    border: none !important;
    background: transparent !important;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    background: transparent !important;
    color: rgb(0 0 0 / 65%);

    & > p {
      padding: 12px 16px;
    }
  }

  &__header {
    display: block;
    padding: 8px 12px;
    width: 100%;
    border-bottom: 1px solid rgb(0 0 0 / 15%);
  }

  &__container {
    display: flex;
    max-width: 100%;
    width: 100%;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    max-width: 100%;
    height: 100%;

    &_recipe_form {
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-footer {
      position: sticky;
      bottom: 0;
      margin-top: 24px;
      padding: 16px;
      border-top: 1px solid rgb(0 0 0 / 15%);
      background: #fff;
    }

    &-step-wrapper {
      display: grid;
      flex-direction: column;
      width: 100%;
      height: calc(100vh - 112px);
      grid-template-rows: 1fr auto;
    }

    &-step {
      overflow-y: auto;
      padding-top: 24px;
      border-radius: 6px;

      &-title {
        position: sticky;
        left: 0;
        padding: 12px 16px;

        &_union {
          padding: 12px 16px 12px 38px;
        }
      }

      &-component {
        max-width: 100%;

        &_filter {
          padding: 12px 16px;
        }
      }

      &-run-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        border: 1px solid rgb(0 0 0 / 15%);
        border-radius: 0 0 6px 6px;
        background: #f9f0ff;
        box-shadow: 0 2px 0 0 rgb(0 0 0 / 2%);
        color: #531dab;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        gap: 8px;

        &_disabled {
          cursor: not-allowed;
        }
      }
    }

    &-run-result {
      overflow-y: auto;
      min-height: 49px;
      max-height: calc(60vh - 90px);
      border-top: 1px solid rgb(0 0 0 / 15%);
      background: #fff;
    }
  }

  &__skeleton {
    padding: 36px 52px 12px 40px;
  }
}
