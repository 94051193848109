.workspaceEditingPanel {
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    align-items: flex-start;
  }

  &__icon {
    color: rgb(0 0 0 / 40%);
  }

  &__footer {
    margin: 0;

    &-button {
      float: right;
    }

    > div {
      margin: 0;
    }
  }
}
