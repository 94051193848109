.copySQLButton {
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
  margin-bottom: -37px;

  &__copyOutlined {
    color: rgb(0 0 0 / 25%);
    font-size: 16px;
  }
}
