.ai-suggestion {
  .ant-dropdown-menu {
    padding: 0;
  }

  .ant-dropdown-menu-title-content {
    padding: 12px 16px;
    font-size: 16px;
  }

  .ant-dropdown-menu .ant-dropdown-menu-item {
    padding: 0;
    border-bottom: 1px solid rgb(0 0 0 / 6%);
  }

  .ant-dropdown-menu .ant-dropdown-menu-item-disabled {
    background: rgb(0 0 0 / 2%);
    color: rgb(0 0 0 / 88%);
    font-weight: 600;

    &:hover {
      background: rgb(0 0 0 / 2%);
      color: rgb(0 0 0 / 88%);
      cursor: auto;
    }
  }
}

.ai-feedback {
  &__container {
    position: relative;
    z-index: 10000;
    overflow: hidden;
    margin-bottom: 10px;
    border: 1px solid #e9ecef;
    border-radius: 10px;
  }

  &__heading {
    margin: 0;
    padding: 12px 16px;
    border-radius: 10px 10px 0 0;
    background-color: #fbfbfb;
    color: #353535;
    font-weight: 600;
    font-size: 16px;
  }

  &__button {
    display: flex;
    box-sizing: border-box;
    padding: 12px 16px;
    width: 100%;
    border: 0 solid #b5b5b5;
    border-top: 1px solid #e9ecef;
    background-color: #fff;
    color: #353535;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;

    &:hover {
      background-color: #eee;
    }

    &:active {
      box-shadow: inset 0 3px 5px rgb(0 0 0 / 12.5%);
    }

    &:disabled {
      border-color: #e9ecef;
      background-color: #e9ecef;
      color: #959595;
      cursor: not-allowed;
    }

    &--loading {
      background-color: #e9ecef;
      color: #6c757d;
      pointer-events: none;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0 0 0 / 10%);
  }
}
