.ai-joinForm-joinRow {
  display: grid;
  align-items: center;
  grid-template-columns: 50% 50% min-content;

  .ant-tag {
    max-width: 236px;
  }

  .ant-select-open .ant-tag {
    display: none;
  }

  &__cell {
    display: flex;
    background: #fff;

    &-select {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      overflow: auto;
      padding: 7px 6px;
      min-height: 54px;
      width: 100%;
      outline-offset: -3px;
      border-bottom: 1px solid #f0f0f0;

      &_error {
        outline: 1px solid #ff4d4f;
      }

      &_disabled {
        opacity: 0.5;
      }

      &_disabled .ant-select-arrow {
        display: none;
      }

      &_calculated .ant-select-arrow {
        display: none;
      }

      &-tags {
        overflow: hidden;
        margin-bottom: 3px;
        max-width: 275px;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;

        & .ant-tag-close-icon svg {
          display: none;
        }
      }
    }

    &__delete-btn {
      padding-left: 4px;
    }

    .ant-select .ant-tag {
      margin-top: 6px;
      margin-bottom: 0;
    }
  }
}
