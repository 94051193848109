@import 'src/assets/styles/constants';
@import 'src/assets/styles/mixins';

.readonly-report-tabs-container {
  &__report-tabs {
    overflow: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: rgb(172 181 191 / 40%);
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background: rgb(172 181 191 / 20%);
    }
  }

  &__main-tab {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 26px;
    width: 100%;
    height: 40px;
    border: none;
    background: none;
    color: $cNavLink;
    text-decoration: none;
    cursor: pointer;
    @include font(14px, 400, 40px, 'Open sans');

    &_active,
    &:hover {
      background: $cBackgroundNavActive;
      color: $cNavLinkActive;

      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 2px;
        background: $cBorderLeftNav;
        content: '';
      }
    }
  }

  &__tab {
    &_dragging {
      border: 1px solid $cBorderLeftNav;
      background: $cBackgroundNavActive;
    }
  }

  &__button-add {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 62px;
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 0;
    background: $cBackgroundAsideRouter;
    box-shadow: inset 0 -1px 0 0 #343948, inset 0 1px 0 0 #343948;
    color: $cNavLink;
    cursor: pointer;
    @include font(13px, 600, 40px, 'Open sans');

    &_active,
    &:hover {
      background: $cBackgroundNavActive;
      color: $cNavLinkActive;
    }
  }
}
