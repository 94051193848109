$color-border-bg: rgb(255 255 255 / 100%);
$color-border-secondary: rgb(0 0 0 / 6%);
$color-icon: rgb(0 0 0 / 45%);
$color-text-heading: rgb(0 0 0 / 88%);

.ai-dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  padding: 3px 0;
  width: 300px;
  border: 1px solid $color-border-secondary;
  border-radius: 6px;
  background-color: $color-border-bg;
  box-shadow: 0 9px 28px 8px rgb(0 0 0 / 5%), 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%);

  &__select-text {
    position: relative;
    display: flex;
    align-items: center;
    padding: 6px 8px;
    width: 300px;
    height: 28px;
    background-color: $color-border-bg;
    gap: 16px;
  }

  &__headline-and-text,
  &__name-attributes {
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: center;
    margin-top: -2px;
    margin-bottom: -2px;
    gap: 12px;
  }

  &__name-attributes {
    margin-right: -399px;
    width: 683px;
  }

  &__cellNumberIcon {
    position: relative;
    margin-top: -1px;
    width: 16px;
    color: $color-icon;
    text-align: center;
  }

  &__icon {
    color: rgb(146 84 222 / 100%);
  }

  &__text-wrapper {
    position: relative;
    margin-top: -1px;
    width: fit-content;
    color: $color-text-heading;
    white-space: nowrap;

    &_gray {
      color: $color-icon;
    }
  }

  &__run-all-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    min-width: 85px;
    height: 24px;
    border: 1px solid rgb(0 0 0 / 15%);
    border-radius: 6px;
    background: #fff;
    font-size: 14px;

    &_gap {
      gap: 8px;
    }
  }

  &__button {
    > .ant-btn-loading-icon {
      color: rgb(146 84 222 / 100%);
    }

    &_disabled {
      color: rgb(0 0 0 / 25%);
      cursor: not-allowed;
    }
  }
}
