.ai-joinForm-additionalSelect {
  display: grid;
  gap: 16px;

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    gap: 12px;

    &-count {
      padding: 1px 6px;
      border-radius: 16px;
      background-color: #531dab;
      color: #fff;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__item {
    &-wrapper {
      display: grid;
      align-items: center;
      min-height: 64px;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      grid-template-columns: 200px 1fr;

      &_error {
        border-color: #ff4d4f;
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &_disabled {
        cursor: not-allowed;
        pointer-events: none;
      }
    }

    &-label {
      display: flex;
      align-items: center;
      padding-left: 16px;
      min-height: 62px;
      border-right: 1px solid #d9d9d9;
      color: #8c8c8c;
      font-size: 16px;
      line-height: 24px;

      &-value {
        overflow: hidden;
        max-width: 164px;
        text-overflow: ellipsis;
      }
    }

    &-custom-button {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 8px 12px;
      min-height: 32px;
      width: 100%;
      border-bottom: 1px solid #f0f0f0;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s;

      span {
        color: #9254de;
        font-weight: 500;
        font-size: 14px;
      }

      &:hover {
        background-color: #eee;
      }
    }

    &-visible-value {
      padding: 0 12px;
      font-size: 14px;
      line-height: 22px;
    }

    & .ant-select-selection-item {
      background: transparent !important;
    }

    &-checkbox {
      padding-right: 8px;

      .ant-checkbox-inner {
        border-color: #531dab !important;
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background: #531dab !important;
      }
    }
  }

  .ant-select-open .ant-select-selection-overflow-item {
    padding-left: 6px;
  }

  .ant-select-open &__item-visible-value {
    display: none;
    outline: 1px solid black !important;
  }
}
