.ai-button {
  padding: 10px 40px;
  box-shadow: 0 -20px 20px #fff;

  &__mainButton {
    position: absolute;
    right: 14px;
    bottom: 16px;
    z-index: 100;
    border-radius: 25px;
    background: #9254de;
    line-height: 1;
  }

  &__toggleButton {
    position: absolute;
    top: 0;
    right: calc(100% + 7px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
