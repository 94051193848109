.ai-button {
  position: fixed;
  right: 50px;
  bottom: 120px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px !important;
  height: 56px !important;
  border-color: #722ed1 !important; /* Purple accent color */
  background-color: #722ed1 !important; /* Purple accent color */
  box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
  transition: all 0.3s ease;

  &:hover {
    border-color: #531dab !important; /* Darker purple for hover */
    background-color: #531dab !important; /* Darker purple for hover */
    box-shadow: 0 6px 16px rgb(0 0 0 / 20%);
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }

  &__icon {
    width: 56px;
    height: 56px;
    object-fit: contain;
  }
}
