@import 'src/assets/styles/constants';
@import 'src/assets/styles/mixins';

.admin-menu {
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 20px;

    &-title {
      margin-bottom: 0 !important;
      color: $cNavAdminLink;
    }

    &-icon {
      color: #8a8692;
      cursor: pointer;
    }
  }

  &__divider {
    margin: 0 !important;
  }

  &__content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 18px 0 0;
  }

  &__category {
    padding: 0 20px;
    height: 40px;
    color: $cNavAdminLink;
    text-transform: uppercase;
    @include font(12px, 600, 40px, 'Open sans');
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 4px 4px;
    padding: 0 16px;
    width: stretch;
    height: 40px;
    border: none;
    background: none;
    color: $cNavAdminLink;
    text-decoration: none;
    cursor: pointer;
    @include font(14px, 400, 40px, 'Open sans');

    > span {
      overflow: hidden;
      width: 100%;
      height: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover,
    &_active {
      border-radius: 8px;
      background: $cNavAdminLinkBackgroundActive !important;
      color: $cNavAdminLinkActive !important;
    }
  }

  .logOut {
    margin: 0;
    margin-top: auto;
    padding: 12px 16px;
    height: auto;

    &:hover {
      border-radius: 0;
    }

    > span {
      line-height: 32px;
    }
  }
}
