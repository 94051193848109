.ai-wizardStepTitle {
  display: flex;
  align-items: center;
  min-height: 46px;
  color: rgb(0 0 0 / 88%);
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  gap: 12px;

  &__input-wrapper {
    display: flex;
    max-width: 450px;

    input {
      padding: 0;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
    }
  }

  &__edit-button {
    color: #531dab;
    font-size: 20px;
    line-height: 20px;
    cursor: pointer;
  }
}
