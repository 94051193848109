.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: #2b235a;

  &_external {
    background: #fff;
  }

  &__spin {
    margin: auto;
  }

  &__container {
    margin: auto;
  }

  &__logo-block {
    display: flex;
    border-radius: 5px 5px 0 0;

    &_image {
      margin: auto;
      padding: 10px;
      max-height: 73px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 430px;
    border-radius: 0 0 5px 5px;
    background-color: #fff;

    &-block {
      margin: 40px 40px 16px;
    }
  }

  &__forgot-block {
    margin-top: 20px;
    text-align: center;
  }

  &__white-link {
    color: #fff;
    text-align: center;
    text-decoration: underline;
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding: 20px;
    border-radius: 0 0 5px 5px;
    background: #f5f5f5;

    & > a {
      text-decoration: underline;
    }
  }
}
