.ai-cerebro {
  overflow-x: auto;
  padding: 12px 16px;
  max-width: 100%;
  width: 100%;

  & .ant-select.ant-select-disabled .ant-select-selector {
    background: transparent;
  }

  & .ant-descriptions-row {
    display: grid !important;
    grid-template-columns: 200px 1fr;
  }

  &__field {
    &_error {
      outline: 1px solid #ff4d4f;
      outline-offset: 8px;
    }

    & textarea {
      overflow-y: hidden;
    }
  }
}

.ai-editorMarkdown_cerebro {
  p {
    color: rgb(0 0 0 / 25%);
    font-size: 16px;
  }
}
