.ai-card {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 24px;
  padding: 12px;
  min-width: 300px;
  min-height: 96px;
  width: 100%;
  border-radius: 6px;
  background: var(--color-fill-tertiary, rgb(0 0 0 / 4%));
  cursor: pointer;
  gap: 16px;

  &__left {
    overflow-x: hidden;

    p {
      margin: 0;
      font-size: 16px;
      line-height: 24px;

      a {
        color: var(--color-text-heading, rgb(0 0 0 / 88%));
      }
    }

    &__date {
      color: var(--color-text-disabled, rgb(0 0 0 / 25%));
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    }
  }

  &__right {
    display: flex;
    width: 14px;
    vertical-align: middle;
  }

  &_current {
    border: 1px solid var(--color-border-primary, rgb(0 0 0 / 8%));
    background: var(--color-fill-primary, rgb(0 0 0 / 8%));
  }

  &_pinned {
    border: 1px solid var(--color-border-pinned, rgb(0 121 255 / 25%));
    background: var(--color-fill-pinned, rgb(0 123 255 / 5%));
  }
}
