$Roboto: 'Roboto', sans-serif;
$OpenSans: 'Open sans', sans-serif;
$cNavLink: #ffffffd6;
$cNavLinkActive: #ff6da9;
$cNavCategory: #8a8692;
$cBorderLeftNav: #f23180;
$cBackgroundAsideRouter: #160b39;
$cBackgroundNavHover: #531dab3d;
$cBackgroundNavActive: #531dab7a;
$error: #ff4d4f;
$cNavAdminLinkActive: #9254de;
$cNavAdminLink: #000000e0;
$cNavAdminLinkBackgroundActive: #f9f0ff;
