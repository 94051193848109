.ai-chat {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #fff;

  .ant-collapse-content {
    border: none !important;
    background: transparent !important;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    background: transparent !important;
    color: rgb(0 0 0 / 65%);

    & > p {
      padding: 12px 16px;
    }
  }

  &__header-wrapper {
    padding: 16px 64px 0;
  }

  &__collapse {
    width: 100%;
    border-radius: 0;
    background: #fff;

    & > .ant-collapse-item:last-child {
      border-radius: 0;

      .ant-collapse-header.ant-collapse-header {
        border-radius: 0;
        color: rgb(0 0 0 / 65%);
      }
    }

    &_noBG {
      border: none;
      background: transparent;
    }

    &_lightPink {
      background: #fff3f3;
    }

    &_lightYellow {
      background: #fffdf3;
    }

    &_newPurple {
      background: #fdfaff;
    }

    &_lightBlue {
      background: #f3faff;
    }

    &_answerPadding {
      padding-left: 0;
    }

    &__header {
      color: rgb(0 0 0 / 65%);
      font-weight: 400;

      &__loader {
        margin-right: 3px;
      }

      &_loading {
        color: #1d39c4;

        .ant-spin {
          margin: 0 6px;
          color: #1d39c4;
          font-size: 14px;
          stroke-width: 2px;
        }
      }
    }
  }

  &__empty-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__messages {
    position: relative;
    flex-grow: 1;
    overflow: auto;
    padding: 16px 70px 0;

    &_isShowTables {
      height: calc(100vh - 490px);
    }
  }

  &__form {
    position: relative;
  }

  &__input {
    margin: 10px 40px;
    box-shadow: 0 -20px 20px #fff;

    &__formItem {
      width: 100%;
    }

    &__actionsBlock {
      height: 50px;
    }
  }

  &__pagination-controls {
    position: relative; // Needed for z-index to work
    z-index: 1000; // High value to bring it to the front
    display: flex;
    align-items: center;
    justify-content: flex-end;

    // margin-top: -30px;
    // margin-right: 10px;

    margin-top: -5px;
    padding: 12px;
    border-right: 1px solid rgb(0 0 0 / 15%);
    border-bottom: 1px solid rgb(0 0 0 / 15%);
    border-left: 1px solid rgb(0 0 0 / 15%);
    border-radius: 0 0 6px 6px;
    background: white;

    &_purple {
      background: #fcf9ff;
    }

    &_lightPink {
      background: #fff3f3;
    }

    &_lightYellow {
      background: #fffdf3;
    }

    &_newPurple {
      background: #fdfaff;
    }

    &_lightBlue {
      background: #f3faff;
    }

    button {
      padding: 0 6px;

      // width: 18px;
      // height: 18px;
      border: none;
      border-radius: 6px;
      background-color: transparent;
      cursor: pointer;

      &:disabled {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
