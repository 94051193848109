.ai-answerTablePreview {
  width: 100%;

  &__controls {
    position: relative;
    display: flex;
    margin-bottom: 24px;
    gap: 8px;

    &-wrapper {
      display: flex;
      justify-content: flex-end;
    }

    &-number {
      color: #531dab;
      font-weight: 700;
    }
  }

  &__filters {
    padding-right: 236px;
    width: 100%;
  }

  &__dropdown {
    position: absolute;
    top: 57px;
    right: 0;
    z-index: 2;
    display: none;
    overflow-y: auto;
    min-width: 272px;
    max-height: 320px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-shadow: -13px 13px 20px rgb(0 0 0 / 5%);
    scrollbar-gutter: stable;

    &_active {
      display: block;
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: rgb(138 134 146 / 40%);
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background: rgb(138 134 146 / 20%);
    }

    &-content {
      display: grid;
      box-sizing: border-box;
      padding: 16px;
      gap: 12px;
    }

    &-group {
      display: grid;
      gap: 12px;

      &-title {
        color: rgb(0 0 0 / 88%);
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }

      &-item {
        display: flex;
        align-items: center;
        color: rgb(0 0 0 / 88%);
        font-size: 14px;
        line-height: 22px;
        cursor: pointer;
        gap: 8px;

        &:hover {
          opacity: 0.9;
        }

        .ant-checkbox-inner {
          border-color: #531dab !important;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
          background: #531dab !important;
        }
      }
    }

    &-list {
      display: grid;
      gap: 6px;

      &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: rgb(0 0 0 / 88%);
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
      }

      &-item {
        display: flex;
        align-items: center;
        color: rgb(0 0 0 / 88%);
        cursor: pointer;
        user-select: none;
        gap: 8px;

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }

  &__multiline-table {
    table {
      border: 1px solid rgb(0 0 0 / 15%);
      border-radius: 6px;
      background: transparent;

      tr,
      td,
      th {
        border: none !important;
      }

      th {
        position: relative;
        overflow: hidden;
        min-width: 150px;
        max-width: 150px;
        text-overflow: ellipsis;
        white-space: nowrap;

        &::after {
          position: absolute;
          top: 10px;
          bottom: 10px;
          left: 0;
          width: 1px;
          height: calc(100% - 20px);
          background: rgb(0 0 0 / 15%);
          content: '';
        }
      }

      th:nth-child(1) {
        &::after {
          width: 0;
          height: 0;
        }
      }
    }
  }
}
