.ai-tool-processing {
  margin: 12px 0 24px;
  width: 100%;

  &__item {
    margin-bottom: 8px;

    br {
      display: none;
    }

    .ant-collapse {
      background: transparent;

      .ant-collapse-item {
        border: none;
        background: transparent;
      }

      .ant-collapse-header {
        display: flex;
        align-items: center;
        padding: 12px !important;
        border-radius: 4px !important;
      }

      .ant-collapse-content {
        border: none;
        background: transparent;

        .ant-collapse-content-box {
          padding: 12px !important;
        }
      }
    }
  }

  &__status {
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 8px;

    &--running {
      color: #1890ff;
    }

    &--success {
      color: #52c41a;
    }

    &--error {
      color: #ff4d4f;
    }
  }

  &__tool-title {
    display: block;
    padding: 8px;
  }

  &__args,
  &__result {
    margin-top: 8px;

    pre {
      overflow-x: auto;
      margin: 0;
      padding: 8px;
      border-radius: 4px;
      background-color: #fff;
    }
  }

  &__args &:first-child {
    margin-top: 0;
  }

  &__result {
    .ant-table-wrapper {
      overflow-y: auto;
      max-height: 500px;
    }
  }
}
