.ai-joinForm {
  display: flex;
  flex-direction: column;
  padding: 16px;
  max-width: 617px;
  gap: 16px;

  &-tableSelect {
    display: grid;
    grid-template-rows: min-content;
  }

  &__tableSelects {
    display: grid;
    grid-template-columns: 50% 50%;

    &-primary,
    &-secondary {
      display: grid;
    }

    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      gap: 12px;
    }
  }

  &__join-key-button {
    width: fit-content;
  }
}
