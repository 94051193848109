.ai-editorMarkdown {
  position: relative;
  width: 100%;
  height: 100%;

  &__copy-button {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 4px;
    background: rgb(255 255 255 / 90%);
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
    transition: all 0.3s ease;

    &:hover {
      background: rgb(255 255 255 / 100%);
      box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
    }
  }

  & img {
    max-width: 100%;
    height: auto;
  }

  &_disabled::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 50%);
    content: '';
    cursor: not-allowed;
  }

  &_bordered {
    overflow: hidden;
    border: 1px solid #dadde6;
    border-radius: 6px;
  }

  & .mdxeditor {
    h2 {
      margin: 20px 0 13px;
      padding-bottom: 7px;
      border-bottom: 1px solid #dbdbdb;
      font-size: 22px;
      line-height: 23px;
    }

    &-toolbar {
      border-bottom: 1px solid #dadde6;
      border-radius: 0;
      background: #f7f9fc;
    }

    &-root-contenteditable {
      & > div {
        padding: 0;
      }

      p {
        margin-bottom: 0;
      }
    }

    li[role='checkbox'] {
      &::before {
        top: 3px;
      }

      &::after {
        top: 6px;
      }
    }

    li[aria-checked='true'] {
      text-decoration: none;
    }

    ::marker {
      color: #ccc;
    }

    & div[aria-label='toggle group'] {
      margin-right: 8px;
    }

    &-diff-source-wrapper {
      padding: 16px 48px 16px 16px;
      min-height: 56px;
    }

    &-rich-text-editor div[aria-label='editable markdown'] {
      padding: 0 !important;
    }
  }

  &_scrollable .mdxeditor {
    &-diff-source-wrapper {
      overflow-y: auto;
      max-height: 50vh;
      scrollbar-width: thin;
    }
  }

  &_hideErrors .mdxeditor {
    &-diff-source-wrapper [class^='_markdownParseError_'] {
      display: none;
    }
  }

  &_readOnly .mdxeditor {
    overflow-x: auto;
    width: 100%;

    table {
      --tw-border-spacing-x: 0px;
      --tw-border-spacing-y: 0px;

      overflow: hidden;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
      width: 100%;
      border: 1px solid rgb(0 0 0 / 10%);
      border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
      border-collapse: collapse;
      border-radius: 8px 8px 0 0;

      th {
        padding: 8px;
        border: 1px solid #f0f0f0;
        background-color: #fafafa;
        color: rgb(0 0 0 / 88%);
        text-align: left;
      }

      td {
        padding: 8px;
        border: 1px solid #f0f0f0;
      }

      tr {
        border-bottom: 1px solid #f0f0f0;
      }
    }
  }

  &_noPadding .mdxeditor {
    & .mdxeditor-diff-source-wrapper {
      display: contents;
      padding: 0 !important;
    }
  }

  &_hideCodeBlockToolbar {
    & [class^='_codeMirrorToolbar_'] {
      display: none;
    }

    & [class^='_codeMirrorWrapper_'] {
      overflow-y: auto;
      max-height: 500px;
    }
  }
}
