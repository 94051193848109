.login-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;

  &__text {
    margin-bottom: 0;
    color: #8c8c8c;
    font-size: 10px;
  }

  &__group-links {
    margin-bottom: 20px;
  }

  &__link {
    padding: 0 18px;
    color: #8c8c8c;
    text-decoration: underline;
    font-size: 10px;
  }
}
