@import 'src/assets/styles/constants';

.sidebar-header {
  align-items: center;
  justify-content: space-between;

  &__workspace_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 0 20px;
  }

  &__current-workspace {
    margin-bottom: 0;
    color: $cNavCategory;
    font-size: 14px;
  }

  &__logo_new {
    display: flex;
    align-items: center;
    margin: 12px 0 12px 20px;
    width: fit-content;
    height: 32px;

    &-img {
      width: 32px;
      height: 32px;
      object-fit: contain;
    }
  }

  &__logo {
    display: flex;
    box-sizing: content-box;
    padding: 0 20px;
    height: 22px;

    &-img {
      width: 100%;
      object-fit: contain;
    }

    &_full-size {
      text-align: center;
    }

    &_medium-size {
      text-align: left;
    }

    &_small-size {
      padding: 10px 20px;
    }
  }

  &__dropdown {
    &-item {
      display: flex;
      align-items: center;

      &.ant-dropdown-menu-item > a {
        display: flex;
        flex: 1;
        align-items: center;

        & > span > span {
          transform: scale(0.7) translateX(-50%) !important;
        }
      }
    }
  }
}
