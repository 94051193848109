.ai-navigation {
  display: block;
  overflow: hidden;
  max-width: 100%;

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    height: 100%;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 13px;
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;

    &-title {
      max-width: 100%;
      opacity: 1;
      transition: all 0.3s ease;

      &_collapsed {
        max-width: 0;
        opacity: 0;
      }
    }
  }

  &__collapse-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(0 0 0 / 45%);
    cursor: pointer;
    transition: transform 0.3s ease;
    transform: rotate(0deg);

    &_collapsed {
      transform: rotate(180deg);
    }
  }

  &__content {
    overflow-y: auto;
    padding: 16px 13px;
    min-width: 300px;
    max-height: calc(100vh - 230px);
    transition: all 0.3s ease;

    &_collapsed {
      padding-left: 7px;
    }

    &_disabled {
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &__step {
    display: grid;
    margin-bottom: 14px;
    column-gap: 16px;
    user-select: none;
    grid-template-columns: 24px 1fr 24px;

    &-number {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 24px;
      min-height: 24px;
      max-width: 24px;
      max-height: 24px;
      border-radius: 50%;
      background: #531dab;
      color: #fff;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      opacity: 0.6;
      cursor: pointer;
      transition: all 0.3s ease;

      &_active {
        opacity: 1;
        cursor: auto;
      }

      &_unsaved {
        background: #ffcd29;
        color: #fff;
      }

      &_failed {
        background: #ff4d4f;
        color: #fff;
      }
    }

    &-title {
      color: rgb(0 0 0 / 45%);
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      transition: all 0.3s ease;

      &_active {
        color: #531dab;
      }
    }

    &-delete-button {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.45;
    }
  }

  &__step_loading &__step-number {
    background: #ffcd29;
  }

  &__sub-step {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    opacity: 0.6;
    cursor: pointer;
    gap: 8px;

    &:last-child {
      padding-bottom: 0;
    }

    &-title {
      display: inline;
      overflow: hidden;
      max-width: 100%;
      color: rgb(0 0 0 / 45%);
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      transition: all 0.3s ease;
    }

    &-icon {
      margin-left: 8px;
      transition: all 0.3s ease;

      &_active {
        transform: rotate(180deg);
      }
    }

    &-container {
      overflow-y: auto;
      padding-left: 24px;
      max-height: 50vh;
      grid-column: 1 / 3;

      .ant-collapse-header {
        display: none !important;
      }
    }

    &::before {
      min-width: 10px;
      min-height: 10px;
      max-width: 10px;
      max-height: 10px;
      border-radius: 50%;
      background: #531dab;
      content: '';
      transition: all 0.3s ease;
    }

    &_active {
      opacity: 1;
    }
  }

  &__add-step-button {
    padding: 16px;

    &-text_collapsed {
      display: none !important;
    }

    button {
      width: 100%;
      transition: all 0.1s ease;
    }

    &_collapsed {
      padding: 16px 4px;

      button {
        padding: 6px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }
  }
}
