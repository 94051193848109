.ai-newCellButtons {
  &__row {
    margin-top: 8px;
    gap: 8px;
  }

  &__divider {
    flex-grow: 1;
    height: 0;
    border: 1px solid rgb(0 0 0 / 15%);

    &_transparent {
      border: none;
    }
  }

  &__button {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 8px;
    height: 32px;
    border-color: rgb(0 0 0 / 15%);
    border-radius: 4px;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    text-align: center;
    gap: 6px;

    svg {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
}
