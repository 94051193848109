.contentLoading {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__error {
    justify-content: center;
    min-height: 100vh;
    background-color: #fff;

    & .ant-result-subtitle {
      font-size: 20px;
    }
  }
}
