.ai-message {
  overflow: auto;
  padding: 16px 70px 0;
  height: calc(100vh - 180px);

  &__content-wrapper {
    overflow-x: auto;
    width: 100%;
  }

  &__edit-icon {
    position: absolute;
    right: 28px;
    bottom: 24px;
    font-size: 18px;
    cursor: pointer !important;

    &_disabled {
      opacity: 0.5;
      cursor: not-allowed !important;
    }
  }

  &__cell-options {
    position: absolute;
    top: 0;
    right: -24px;
    font-size: 18px;
    cursor: pointer;

    &_disabled {
      cursor: not-allowed;
    }

    &:active {
      cursor: grabbing;
    }
  }

  &__item {
    position: relative;
    display: flex;
    width: 100%;
    border: 1px solid rgb(0 0 0 / 15%);
    border-radius: 6px;
    color: rgb(0 0 0 / 65%);

    &_edit {
      overflow: hidden;
      padding-right: 0;
    }

    &_collapse {
      padding: 0;
    }

    &_purple {
      background: #fcf9ff;
    }

    &_lightPink {
      background: #fff3f3;
    }

    &_lightYellow {
      background: #fffdf3;
    }

    &_newPurple {
      background: #fdfaff;
    }

    &_lightBlue {
      background: #f3faff;
    }

    &_red {
      background: #fff2f0;
    }

    &_blue {
      background: #f1f9ff;
    }

    &_feedback {
      margin-top: 25px;
    }

    &_noMargin {
      margin: 0;
    }

    &_topMargin {
      margin-top: 24px !important;
    }

    &_noPadding {
      padding: 0;
    }

    &_noBorder {
      border-width: 0;
    }

    &_borderTop {
      min-height: 80px;
      border: 1px solid rgb(0 0 0 / 15%);
      border-radius: 6px 6px 0 0;
    }

    &_borderStart {
      border-top: 1px solid rgb(0 0 0 / 15%);
    }

    &_borderCenter {
      border-right: 1px solid rgb(0 0 0 / 15%);
      border-bottom: 1px solid rgb(0 0 0 / 15%);
      border-left: 1px solid rgb(0 0 0 / 15%);
      border-radius: 0;
    }

    &_borderEnd {
      border-right: 1px solid rgb(0 0 0 / 15%);
      border-bottom: 1px solid rgb(0 0 0 / 15%);
      border-left: 1px solid rgb(0 0 0 / 15%);
      border-radius: 0 0 6px 6px;
    }

    &.js-plotly-plot {
      overflow: hidden;
      margin-bottom: -4px;
      min-height: 500px;
      width: 100%;

      .svg-container {
        position: static !important;
      }
    }
  }

  &__index {
    position: absolute;
    top: 2px;
    right: 6px;
    z-index: 3;
    font-weight: bold;
  }

  &__avatar {
    margin-top: 7px;
    margin-right: 10px;
    height: 35px;
  }

  &__text {
    display: flex;
    align-items: center;
    padding: 0 12px;

    &_noStyle {
      display: block;
      align-items: start;
      margin-right: 0;
      padding: 0;
      background: transparent;
    }

    &_edit {
      display: block;
      margin-top: 0;
      margin-right: 0;
      padding-right: 0;
      padding-left: 0;
      width: 100%;

      .ai-editorMarkdown {
        width: 100%;
        height: auto;
      }
    }

    p:last-child {
      margin: 0;
    }
  }

  &__edit-buttons {
    margin-top: 10px;
    margin-left: 20px;
    padding-bottom: 20px;
  }

  &__edit-submit-button {
    border: 1px solid var(--purple-5, #9254de);
    background: var(--purple-5, #9254de);
    box-shadow: 0 2px 0 0 rgb(0 0 0 / 2%);
  }

  &__multiline-table {
    width: 100%;
  }
}
