.ai-union {
  position: relative;
  padding: 16px 0;
  max-width: 100%;
  width: 100%;
  background: #fff;
  transform: translate3d(0, 0, 0);

  &__title {
    position: sticky;
    left: 16px;
    box-sizing: border-box;
    padding-top: 16px;
    min-height: 33px;
    width: fit-content;

    &-wrapper {
      position: relative;
    }
  }

  &__add-source-btn {
    position: absolute;
    right: 56px;

    &-wrapper {
      width: 100%;
      height: 32px;
    }
  }

  &__save-controls {
    position: sticky;
    left: 22px;
    width: fit-content;

    &-wrapper {
      position: relative;
      padding: 7px 6px;
    }
  }

  &__add-field-btn {
    position: sticky;
    left: 22px;
    width: fit-content;

    &-wrapper {
      position: relative;
      padding: 7px 6px;
    }
  }

  & .ant-select.ant-select-disabled .ant-select-selector {
    background: transparent;
  }

  &__header {
    position: sticky;
    top: -24px;
    z-index: 3;
    display: flex;
    max-width: 100%;
    width: 100%;

    &-cell {
      position: relative;
      display: flex;
      align-items: center;
      padding: 12px;
      min-width: 342px;
      min-height: 66px;
      max-width: 342px;
      border-bottom: 1px solid #f0f0f0;
      background: #fafafa;
      place-content: center center;

      &-delete-btn {
        position: absolute;
        top: 0;
        right: 0;
      }

      &-complete-panel {
        position: absolute;
        top: 72px;
        left: 4px;
        padding-top: 12px;
        min-height: 60px;
        width: calc(100% - 8px);
        border: 1px solid #f0f0f0;
        border-radius: 6px;
        background: white;

        &-option {
          display: flex;
          align-items: center;
          margin: 0;
          padding: 8px 12px;
          cursor: pointer;
          gap: 8px;

          &_selected {
            background-color: #f9f0ff;
          }

          &_global {
            margin-top: 12px;
            border-top: 1px solid #f0f0f0;
            border-bottom: 1px solid #f0f0f0;
          }
        }

        &-value-text {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &_selected {
            font-weight: 500;
          }
        }

        &-checkbox {
          padding-right: 8px;

          .ant-checkbox-inner {
            border-color: #531dab !important;
          }

          .ant-checkbox-checked .ant-checkbox-inner {
            background: #531dab !important;
          }
        }

        &-btn {
          cursor: pointer;
        }

        &-list {
          overflow-y: auto;
          max-height: 300px;
          scrollbar-gutter: stable;

          &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background: rgb(138 134 146 / 40%);
          }

          &::-webkit-scrollbar-track {
            border-radius: 4px;
            background: rgb(138 134 146 / 20%);
          }
        }
      }

      &-value {
        display: flex;
        align-items: center;
        overflow: hidden;
        max-width: 100%;
        width: 100%;
        gap: 4px;

        &-text {
          display: grid;
          overflow: hidden;
          max-width: 100%;
          width: 100%;
          text-overflow: ellipsis;
          font-weight: 500;
          grid-template-columns: 1fr min-content;
        }
      }

      &:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
        min-width: 442px;
        max-width: 442px;
        transition: box-shadow 0.3s ease;
      }

      &_scrolled:first-child {
        box-shadow: 16px 4px 15px 0 rgb(0 0 0 / 5%);
      }
    }
  }

  &__conditions-wrapper {
    position: relative;
  }

  &__conditions {
    position: sticky;
    left: 16px;
    width: fit-content;
  }
}
