@use 'sass:list';
@use 'sass:math';

@import './constants';

$lightFontWeight: 300;
$regularFontWeight: 400;
$mediumFontWeight: 500;
$semiBoldFontWeight: 600;
$boldFontWeight: 700;

@mixin font($size, $fontWeight: 400, $lineHeight: normal, $fontFamily: $Roboto) {
  font: $fontWeight list.slash(#{math.div($size, 16px)}rem, $lineHeight) $fontFamily;
}
