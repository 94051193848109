.ant-collapse-header {
  width: fit-content;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {
  height: 24px;
}

.ai-message {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #fff;

  &__collapse {
    width: 100%;
    border-radius: 0;
    background: #fff;

    & > .ant-collapse-item:last-child {
      border-radius: 0;

      .ant-collapse-header.ant-collapse-header {
        border-radius: 0;
      }
    }

    &_noBG {
      border: none;
      background: transparent;
    }

    &__header {
      color: rgb(0 0 0 / 65%);
      font-weight: 400;

      &__loader {
        margin-right: 3px;
      }

      &_loading {
        color: #1d39c4;

        .ant-spin {
          margin: 0 6px;
          color: #1d39c4;
          font-size: 14px;
          stroke-width: 2px;
        }
      }
    }
  }

  &__empty-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__messages {
    position: relative;
    flex-grow: 1;
    overflow: auto;
    padding: 16px 70px 0;

    &_isShowTables {
      height: calc(100vh - 490px);
    }
  }

  &__form {
    position: relative;
  }

  &__input {
    margin: 10px 40px;
    box-shadow: 0 -20px 20px #fff;

    &__formItem {
      width: 100%;
    }

    &__actionsBlock {
      height: 50px;
    }
  }
}
