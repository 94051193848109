.ai-joinForm-joinSelect {
  &__wrapper {
    display: grid;
    align-items: center;
    min-height: 64px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    grid-template-columns: 200px min-content 1fr;

    &_error {
      border-color: #ff4d4f;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__label {
    display: flex;
    align-items: center;
    padding-left: 16px;
    min-height: 62px;
    border-right: 1px solid #d9d9d9;
    color: #8c8c8c;
    font-size: 16px;
    line-height: 24px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__description {
    display: flex;
    align-items: center;
    padding: 12px 72px 12px 8px;
    border: 1px solid rgb(146 84 222);
    border-radius: 6px;
    background: #f9f0ff;
    color: #000;
    font-size: 14px;
    line-height: 22px;
    gap: 8px;
  }
}
