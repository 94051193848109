@import 'src/assets/styles/constants';
@import 'src/assets/styles/mixins';

.sidebarSPA {
  width: 220px;
  height: 100%;

  &__loading {
    display: flex;
    align-items: center;
    align-self: center;
    height: 100vh;
  }

  &__alert {
    margin: 0 0 14px;
    padding: 0 6px;

    &_link {
      color: #4183c4 !important;
    }
  }

  &__menu {
    position: fixed;
    z-index: 1001;
    display: flex;
    flex-direction: column;
    width: 220px;
    height: 100%;
    background: $cBackgroundAsideRouter;

    &-items {
      flex-grow: 1;
      overflow: auto;
      width: inherit;

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: rgb(138 134 146 / 40%);
      }

      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background: rgb(138 134 146 / 20%);
      }

      > a {
        text-decoration: none;
      }
    }
  }

  &__footer {
    bottom: 0;
    display: flex;
    flex-direction: column;
    color: #ffffffd6;

    &-user {
      &-link {
        display: flex;
        flex: 1;
        align-items: center;
        overflow: hidden;
        margin-right: 12px;
        height: 100%;
        color: $cNavLink;
        text-decoration: none;

        &-name {
          flex-grow: 1;
          overflow: hidden;
          margin-left: 12px;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
        }

        &-icon {
          font-size: 20px;
          cursor: pointer;
        }
      }

      &-info {
        display: flex;
        align-items: center;
        padding: 5px 0 0;
        @include font(14px, 400, 40px, 'Open sans');

        &-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px 16px;
          width: 100%;
          background: transparent;
          cursor: pointer;

          &:hover {
            background: $cBackgroundNavActive;
          }
        }
      }
    }

    &-logout {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 4px 16px 0;
      padding: 0 16px;
      height: 40px;
      border: none;
      background: none;
      color: $cNavCategory;
      cursor: pointer;
      @include font(14px, 400, 32px, 'Open sans');

      &:hover {
        border-radius: 8px;
        background: $cBackgroundNavHover;
      }
    }
  }

  &__category {
    margin: 0 20px;
    height: 40px;
    color: $cNavCategory;
    text-transform: uppercase;
    @include font(12px, 600, 40px, 'Open sans');
  }

  &__report-tabs {
    overflow: auto;
    height: 240px;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 16px 4px;
    padding: 0 16px;
    width: stretch;
    height: 40px;
    border: none;
    background: none;
    color: $cNavLink;
    text-decoration: none;
    cursor: pointer;
    @include font(14px, 400, 40px, 'Open sans');

    > span {
      overflow: hidden;
      height: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover,
    &_active {
      border-radius: 8px;
    }

    &:hover {
      background: $cBackgroundNavHover;
    }

    &_active {
      background: $cBackgroundNavActive !important;
    }
  }
}
