.ai-wizardTable {
  &__layout {
    background-color: #fff;
  }

  &__table {
    &-container {
      height: calc(100% - 174px);

      .ant-pagination {
        padding-right: 16px;
      }

      .ant-table-cell {
        min-width: 150px;

        &:last-child {
          min-width: 50px !important;
          max-width: 50px !important;
          width: 50px !important;
        }
      }
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-title {
        display: flex;
        align-items: center;
      }
    }
  }

  &__content {
    padding: 0 50px;
    min-height: 100%;
    height: calc(100% - 98px);

    &__header {
      display: flex;
      justify-content: space-between;
      padding: 18px 0;
      height: fit-content;
      background: #fff;

      &__search {
        width: 200px;
      }
    }
  }

  &__buttonDelete {
    color: black;
  }

  &__contextMenuColumn {
    min-width: 50px !important;
    max-width: 50px !important;
    width: 50px !important;
  }

  &__contextMenu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
