.ai-row {
  &__cell {
    display: flex;
    background: #fff;

    &-select {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      overflow: auto;
      padding: 7px 6px;
      min-width: 342px;
      min-height: 54px;
      max-width: 342px;
      outline-offset: -3px;
      border-bottom: 1px solid #f0f0f0;

      &_error {
        outline: 1px solid #ff4d4f;
      }

      &_active {
        outline: 1px solid #531dab;
      }

      &_disabled {
        opacity: 0.5;
      }

      &_disabled .ant-select-arrow {
        display: none;
      }

      &_calculated .ant-select-arrow {
        display: none;
      }

      &-formula-button {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 7px;
        border: 1px solid #b37feb;
        border-radius: 4px;
        background: #fff;
        color: #b37feb;
        font-size: 14px;
        line-height: 22px;
        opacity: 0;
        cursor: pointer;
        transition: opacity 0.3s ease;
        gap: 8px;

        &-wrapper {
          position: absolute;
          top: 7px;
          right: 16px;

          &_active {
            top: 8px;
          }
        }

        &_active {
          padding: 0;
          border: none;
          opacity: 1;

          span {
            display: none;
          }

          svg {
            width: 20px;
            height: 20px;

            path {
              stroke: #531dab;
            }
          }
        }

        &_error {
          svg path {
            stroke: #ff4d4f;
          }
        }
      }

      &-tags {
        overflow: hidden;
        margin-bottom: 3px;
        max-width: 235px;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;

        & .ant-tag-close-icon svg {
          display: none;
        }
      }
    }

    &:first-child &-select {
      display: grid;
      min-width: 442px;
      max-width: 442px;
      grid-template-columns: 1fr 150px;
    }

    & &-select > .ant-select {
      overflow-x: hidden;
      max-width: 280px;
    }

    &-custom-button {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 8px 12px;
      min-height: 32px;
      width: 100%;
      border-bottom: 1px solid #f0f0f0;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s;
      gap: 4px;

      span {
        color: #9254de;
        font-weight: 500;
        font-size: 14px;
      }

      &:hover {
        background-color: #eee;
      }

      &_ai {
        box-sizing: border-box;
      }

      &_filter {
        span {
          color: rgb(0 0 0 / 88%);
          font-weight: 400;
        }
      }
    }
  }

  &__columns {
    position: relative;
    display: flex;

    &:active {
      z-index: 10;
    }

    & .ai-row__cell:first-child {
      position: sticky;
      left: 0;
      z-index: 2;
      transition: box-shadow 0.3s ease;
    }

    & .ai-row__cell_editable:first-child {
      left: -40px;
    }

    & .ai-row__cell_scrolled:first-child {
      box-shadow: 16px 4px 15px 0 rgb(0 0 0 / 5%);
    }

    &-btns-wrapper {
      display: flex;
      align-items: center;
      opacity: 0;
      transition: 0.3s all ease;
      pointer-events: none;
    }

    &-delete-btn_hidden,
    &-move-btn_hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__columns:hover &__columns-btns-wrapper {
    opacity: 1;
    pointer-events: auto;
  }

  &__columns:active &__columns-btns-wrapper {
    opacity: 1;
    pointer-events: auto;
  }

  &__columns:hover &__cell-select-formula-button {
    opacity: 1;
  }

  &__columns .ant-select .ant-tag {
    margin-top: 6px;
    margin-bottom: 0;
  }
}
