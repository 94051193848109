.ai-joinForm-tableSelect {
  &__description {
    padding: 8px 16px;
    border-bottom: 1px solid #f0f0f0;
    background: #fafafa;
    color: #000;
    font-size: 14px;
    line-height: 22px;
  }

  &__header {
    display: flex;
    max-width: 100%;
    width: 100%;

    &-cell {
      position: relative;
      display: flex;
      align-items: center;
      padding: 12px 6px;
      min-height: 66px;
      border-bottom: 1px solid #f0f0f0;
      background: #fafafa;
      place-content: center center;

      &:last-child {
        margin-left: -1px;
      }

      &_error {
        outline: 1px solid #ff4d4f;
        outline-offset: -4px;
      }

      &-value {
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 0 6px;
        max-width: 100%;
        width: 100%;
        gap: 4px;

        &-text {
          overflow: hidden;
          max-width: 100%;
          text-align: center;
          text-overflow: ellipsis;
          font-weight: 500;
        }
      }
    }
  }
}
