.ai-dimensionsTable {
  border: 1px solid #d9d9d9;
  border-radius: 6px;

  &__row {
    display: grid;
    align-items: center;
    grid-template-columns: 200px 1fr;

    &:last-child {
      margin-top: -1px;
    }

    &-label {
      box-sizing: border-box;
      padding: 17px 16px;
      min-width: 200px;
      color: #8c8c8c;
      font-size: 16px;
      line-height: 24px;
    }

    &-select {
      overflow: auto;
      padding: 12px 8px;
      max-height: 106px;
      border-left: 1px solid #d9d9d9;

      &_error {
        outline: 1px solid #ff4d4f;
        outline-offset: 1px;
      }

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: rgb(172 181 191 / 40%);
      }

      &::-webkit-scrollbar-track {
        background: rgb(172 181 191 / 20%);
      }

      &-tags {
        overflow: hidden;
        margin-bottom: 3px;
        max-width: 275px;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;

        & .ant-tag-close-icon svg {
          display: none;
        }
      }
    }

    &:first-child {
      border-bottom: 1px solid #d9d9d9;
    }
  }
}
