.ai-wizardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1px;
  padding: 12px 16px;
  border-top: 1px solid rgb(0 0 0 / 15%);

  &__right-sider {
    display: flex;
    gap: 12px;

    &-message {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      gap: 8px;
    }
  }
}
