.ai-headerBlock {
  display: flex;
  justify-content: space-between;
  min-height: 32px;
  color: var(--color-icon, rgb(0 0 0 / 45%));
  vertical-align: middle;
  font-size: 12px;

  &__left-sider {
    display: flex;
    align-items: center;
    vertical-align: middle;
    line-height: 29px;
  }

  &__back-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    transition: all 0.3s ease;
    gap: 4px;

    &:hover {
      background-color: rgb(0 0 0 / 6%);
      color: rgb(0 0 0 / 88%);
    }
  }

  &__switchText {
    margin-right: 8px;
    color: rgb(38 38 38 / 100%);
    font-weight: 600;
    line-height: 23px;
  }

  &__calendar {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    font-size: 20px;
  }

  &__contextMenu {
    cursor: pointer;
  }

  &__page-title {
    display: flex;
    align-items: center;
    min-height: 32px;
    color: rgb(0 0 0 / 88%);
    white-space: nowrap;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;

    &::before {
      padding: 0 4px;
      color: var(--color-icon, rgb(0 0 0 / 45%));
      content: '/';
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__state {
    display: flex;
    align-items: center;
    min-height: 32px;
    line-height: 18px;

    &::before {
      padding: 0 4px;
      content: '|';
    }
  }
}
