.forgot-password-form {
  &__title.ant-typography {
    margin-bottom: 40px;
    color: #2b235a;
    text-align: center;
    font-weight: 300;
    font-size: 24px;
  }

  &__text {
    display: block;
    margin-bottom: 20px;
    color: #2b235a;
    text-align: center;
  }

  &__button {
    width: 100%;
    border-color: #ff3186;
    background-color: #ff3186;

    &:focus {
      border-color: #ff3187d0;
      background-color: #ff3187d0;
    }

    &:hover {
      border-color: #ff3187d0;
      background-color: #ff3187d0;
    }
  }
}
