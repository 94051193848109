.drop-down-menu-owner {
  position: absolute;
  top: 50%;
  right: 12px;
  display: none;
  font-size: 16px;
  cursor: pointer;
  transform: translateY(-50%);

  &__description {
    display: block;
    margin-bottom: 5px;
  }

  &__menu-content {
    max-width: 374px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);

    .ant-dropdown-menu {
      box-shadow: none;
    }

    .ant-divider {
      margin: 5px 0;
    }
  }

  &__menu-description-container {
    padding: 0 15px 5px;
  }
}
