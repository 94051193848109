.azure-button {
  display: flex;
  align-items: center;
  margin-top: 24px;
  padding: 0;
  width: 100%;
  height: 40px;

  & > span:last-child {
    margin: 8px auto;
    color: #595959;
    font-size: 16px;
  }

  &__icon {
    padding: 8px 6px;
    height: 38px;
    border-right: 1px solid #d9d9d9;
    background-color: #fafafa;
  }

  &:last-child {
    margin-bottom: 24px;
  }
}
