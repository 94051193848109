.workspace-menu {
  .ant-drawer-header {
    background-color: rgb(0 0 0 / 2%);
  }

  .ant-list-vertical {
    margin-top: 15px;

    .ant-list-item {
      position: relative;
      padding-left: 20px;
      border-radius: 6px;
      cursor: pointer;

      &:hover {
        background: rgb(0 0 0 / 2%);

        .drop-down-menu-owner {
          display: block;
        }
      }

      &::before {
        position: absolute;
        top: 50%;
        left: 11px;
        display: block;
        width: 3px;
        height: 70%;
        border-radius: 15px;
        background: #efdbff;
        content: '';
        transform: translateY(-50%);
      }

      .ant-list-item-meta {
        margin-block-end: 0;

        .ant-list-item-meta-title {
          margin-bottom: 0;
        }
      }

      .ant-list-item-action {
        margin-block-start: 0;
      }
    }
  }

  &__item_selected {
    background: #f9f0ff;
  }

  &__product-link {
    display: block;
    padding: 15px;
    width: 100%;
    border-radius: 6px;
    background: rgb(0 0 0 / 2%);
    color: rgb(0 0 0 / 88%);
    font-size: 14px;
  }

  &__title-container {
    margin-top: 24px;
    margin-bottom: 12px;
  }

  &__search-icon {
    color: #89829740;
  }

  &__new-icon {
    font-size: 24px;
  }
}
