.cell {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 7px 6px;
  min-height: 64px;
  width: 72px;
  border: 1px solid #f0f0f0;
  background: rgba(0 0 0 / 2%);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  & .ant-select.ant-select-disabled .ant-select-selector {
    background: transparent;
  }
}

.cellInput {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  overflow: auto;
  min-width: 56px;
  min-height: 64px;
  border: 1px solid rgb(0 0 0 / 6%);
  background: #fff;
}

.cellInputError {
  outline: 1px solid #ff4d4f;
  outline-offset: -3px;
}

.cellWide {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 7px 6px;
  min-height: 64px;
  width: 210px;
  border: 1px solid rgb(0 0 0 / 6%);
  background: rgb(0 0 0 / 2%);
}

.flex {
  display: flex;
}

.uppercase {
  text-transform: uppercase;
}

.gridCells {
  display: grid;
  min-width: calc(100% + 72px);
  grid-template-columns: 1fr 72px;
}

.center {
  align-items: center;
}

.justifyContentFlexEnd {
  justify-content: flex-end;
}

.justifyContentFlexStart {
  justify-content: flex-start;
}

.padding7x6 {
  padding: 7px 6px;
}

.paddingY16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.minWidth170 {
  min-width: 170px;
}

.minWidth630 {
  overflow-x: hidden;
  min-width: 630px;
}

.width366 {
  min-width: 366px;
}

.ml720 {
  margin-left: 720px;
}

.mtMinus38 {
  margin-top: -38px;
}

.ml578 {
  margin-left: 578px;
}

.groupType {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 7px 6px;
  width: 72px;
  border-left: 2px solid rgb(0 0 0 / 15%);
  background: rgb(0 0 0 / 2%);
  gap: 12px;
}

.deleteBtn {
  position: absolute;
  z-index: 1000;
  opacity: 0;

  &:hover {
    background: transparent !important;
    opacity: 0.7;
  }
}

.rowControls {
  position: relative;
  opacity: 0;
  transition: all 0.2s;

  &:hover {
    opacity: 0.7;
  }
}

.showRowControls {
  position: relative;
  opacity: 0.7;
}

.tags {
  margin-bottom: 3px;
  white-space: normal;
  word-break: break-all;

  & .ant-tag-close-icon svg {
    display: none;
  }
}

.datePickerExtraFooter {
  padding: 8px 6px;
  width: 100%;

  > div {
    flex: 1;

    > div {
      width: 100%;
    }
  }
}
