.ai-aggregationTable {
  display: grid;
  gap: 12px;

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }

  &__table {
    display: grid;

    &-header {
      display: grid;
      border: 1px solid #f0f0f0;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      background: #fafafa;
      grid-template-columns: 50% 50%;

      &-label {
        padding: 8px 16px;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;

        &:first-child {
          border-right: 1px solid #f0f0f0;
        }
      }
    }

    &-rows {
      border-right: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
      border-left: 1px solid #f0f0f0;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &-row {
      display: grid;
      align-items: center;
      min-height: 64px;
      grid-template-columns: 50% 50% min-content;
    }

    &-cell {
      display: flex;
      align-items: center;
      padding: 12px 8px;
      min-height: 64px;
      border-top: 1px solid #f0f0f0;

      &:first-child {
        border-right: 1px solid #f0f0f0;
      }

      &_error {
        outline: 1px solid #ff4d4f;
        outline-offset: -4px;
      }
    }

    &-add-button {
      width: fit-content;
    }
  }

  & .ant-tag {
    overflow: hidden;
    max-width: 224px;
    text-overflow: ellipsis;
  }
}
