@import '~antd/dist/reset.css';

.app {
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  width: 100%;

  &__siderbar {
    flex-shrink: 0;
  }

  &__content {
    position: relative;
    flex-grow: 1;
    min-width: 0;
    min-height: 100vh;
  }
}

.logoSPA {
  width: 220px;
  text-align: center;
}

.logoSPA-img {
  left: 0;
  padding: 4px 0;
  width: 180px;
  object-fit: contain;
}
