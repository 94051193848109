.ai-leftSider {
  box-sizing: border-box;
  padding: 16px;
  height: calc(100vh);
  background: var(--color-fill-alter, rgb(0 0 0 / 2%));

  &__characterCounter {
    position: absolute;
    right: 10px;
    bottom: 20px;
    color: #555;

    &_collapsed {
      display: none;
    }
  }

  &__header {
    min-height: 41px;

    &__actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__new-btn {
      &_collapsed {
        display: none;
      }
    }

    &__agency {
      margin-top: 8px;
      width: 100%;

      &_collapsed {
        display: none;
      }
    }

    &__collapse-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      padding: 0 8px;
      height: 32px;
      cursor: pointer;
      transition: transform 0.3s ease;
      transform: rotate(0deg);

      &_collapsed {
        margin: 0 auto;
        transform: rotate(180deg);
      }
    }

    &__icon,
    &__iconback {
      height: 32px;
      cursor: pointer;
    }
  }

  &__toggle {
    overflow: hidden;
    max-width: 100%;
    max-height: 32px;

    &_collapsed {
      max-width: 0;
      transition: max-width 0.3s ease;
    }
  }

  &__chats {
    overflow-y: auto;
    box-sizing: border-box;
    padding-right: 16px;
    max-width: 100%;
    height: calc(100vh - 132px);
    transition: max-width 0.3s ease;

    &_collapsed {
      overflow: hidden;
      padding: 0;
      max-width: 0;
    }
  }

  &__rules {
    max-width: 100%;
    transition: max-width 0.3s ease;

    &_collapsed {
      overflow: hidden;
      padding: 0;
      max-width: 0;
    }
  }
}
